.profileScreen {
    height: 100vh;
    color: var(--text-color);
}

.profileScreen__body{
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 100px;
    max-width: 800px;
}

.profileScreen__info {
    display: flex;
}

.profileScreen__info > h1 {
    font-size: 60px;
    font-weight: 400;
    border-bottom: 1px solid #282c2d;
    margin-top: 20px;
}

.profileScreen__info > img {
    margin-top: 20px;
    height: 80px;
    border-radius: 4px;
}

.profileScreen__details {
    margin-top: 20px;
    color: var(--text-color);
    margin-left: 15px;
    flex: 1;
}

.profileScreen__details > h2 {
    background-color: var(--secondary-color);
    padding: 15px;
    font-size: 15px;
    padding-left: 20px;
    color: black;
    font-family: 'InterTight', sans-serif;
    border-radius: 4px;

}

.profileScreen__form {
    margin-top: 20px;
}

.profileScreen__form > h3 {
    font-size: 1.5rem;
    margin-bottom: 20px;
}

.profileScreen__form > form > button {
    padding: 10px 10px;
    font-size: 1.5rem;
    margin-top: 10px;
    width: 100%;
    color: var(--text-color);
    background-color: var(--primary-button-color);
    font-weight: 600;
    border: none;
    cursor: pointer;
    font-family: 'InterTight', sans-serif;
    border-radius: 4px;
}