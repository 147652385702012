.onboardingScreen {
    position: relative;
    height: 100%;
    background: url("https://cms.cosmoclube.com/media/background.webp") center no-repeat;
    background-size: cover;
}

.onboardingScreen__form > h1{
    font-size: 32px;
    margin-bottom: 30px;
}

.onboardingScreen__form > h2{
    text-align: left;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 20px;
    font-family: 'InterTight', sans-serif;
}

.onboardingScreen__form > form > button {
    padding: 10px 10px;
    font-size: 20px;
    color: var(--text-color);
    background-color: var(--primary-button-color);
    font-weight: 600;
    border: none;
    cursor: pointer;
    width: 100%;
    margin-top: 10px;
    font-family: 'InterTight', sans-serif;
    border-radius: 4px;
}

.onboardingScreen__body {
    position: absolute;
    top: 5%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    z-index: 1;
    color: var(--text-color);
    padding: 20px;
    left: 0;
    right: 0;
}

.onboardingScreen__gradient {
    width: 100%;
    z-index: 1;
    height: 100vh;
    background: rgba(45, 45, 35, 0.4);
    background-image: linear-gradient(
        to top,
        rgba(45, 45, 35, 0.8) 0%,
        rgba(45, 45, 35, 0) 60%,
        rgba(45, 45, 35, 0.8) 100%
    );
}

.onboardingScreen__form {
    max-width: 300px;
    padding: 50px;
    margin-left: auto;
    margin-right: auto;
    background: rgba(45, 45, 35, 0.95);
    border-radius: 10px;
}

.singupScreen__link:hover {
    cursor: pointer;
    text-decoration: underline;
}
