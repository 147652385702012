.searchScreen {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.searchScreen__contents {
  margin: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-self: center;
}

.searchScreen__contents__poster {
  position: relative;
  max-width: 250px;
  margin-right: 20px;
  margin-bottom: 20px;
  object-fit: contain;
  transition: transform 450ms;
  cursor: pointer;
}

.searchScreen__contents__poster:hover {
  transform: scale(1.08);
  opacity: 1;
}

.searchScreen__noResults {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
}

.searchScreen__noResults > h1 {
  font-size: 1.5rem;
  color: var(--text-color);
  margin-bottom: 20px;
  text-align: center;
}

.searchScreen__noResults > button {
  font-size: 1rem;
  width: 150px;
  color: var(--text-color);
  background-color: var(--primary-color);
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 5px;
}


