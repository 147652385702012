.nav {
    position: fixed;
    top: 0;
    padding: 20px;
    width: 100%;
    height: 30px;
    z-index: 2;

    /* Animations */
    transition-timing-function: ease-in;
    transition: all 0.5s;
}

.nav__contents {
    display: flex;
    justify-content: space-between;
}

.nav__black {
    background-color: var(--background-color);
}

.nav__logo {
    position: fixed;
    top: 10px;
    left: 0px;
    width: 50px;
    object-fit: contain;
    padding-left: 10px;
    cursor: pointer;
}

.nav__search {
    position: relative;
    display: flex;
    align-items: center;
    background-color: var(--background-color);
    border-radius: 8px;
    width: 200px;
    @media (min-width: 768px) {
        width: 400px;
    }
    height: 30px;
    left: calc(50% - 120px);
    @media (min-width: 768px) {
        left: calc(50% - 225px);
    }
    border: 1px solid var(--text-color);
}

.nav__search__input {
    border: none;
    background-color: var(--background-color);
    width: 100%;
    color: var(--text-color);
    padding-left: 10px;
    margin-left: 5px;
}

.nav__search__input:focus {
    outline: none;
}

.nav__search__icon {
    color: var(--background-color);
    padding: 8px;
    background-color: var(--text-color);
    border-radius: 0px 8px 8px 0px;
    cursor: pointer;
}

.nav__dehaze {
    position: fixed;
    color: var(--text-color);
    left: 0;
    top: 18px;
    width: 100px;
    object-fit: contain;
    padding-left: 20px;
    cursor: pointer;
}

.nav__avatar {
    cursor: pointer;
    position: fixed;
    right: 20px;
    width: 32px;
    height: 32px;
    border-radius: 4px;
}

.nav__dropdown {
    position: fixed;
    top: 60px;
    right: 20px;
    background-color: #4d4d3b;
    list-style: none;
    padding: 20px 20px 0px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: left;
    z-index: 2;
    transition-timing-function: ease-in;
    transition: all 0.5s;
    border-radius: 4px;
}

.nav__dropdown__item > button {
    display: flex;
    align-items: center;
    color: var(--text-color);
    background-color: rgba(0, 0, 0, 0);
    border: none;
    font-size: 1rem;
    cursor: pointer;
    margin-bottom: 20px;
}

.nav__dropdown__item > button:hover {
    color: white;
}

.nav__dropdown__item > button > svg {
    margin-right: 15px;
}