.serieScreen {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
}

.serieScreen__body {
    display: flex;
    flex-direction: row;
}

.serieScreen__video {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 100px;
    padding-left: 10px;
    padding-right: 10px;
}

.serieScreen__player > div > video {
    border-radius: 10px;
}

.serieScreen__video > button {
    margin-bottom: 20px;
    align-self: start;
    border: none;
    background-color: rgba(0, 0, 0, 0.0);
    color: #ffffffb4;
    cursor: pointer;
    font-size: 1rem;
}
.serieScreen__info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 710px;
}

.serieScreen__info h1 {
    font-size: 1.4rem;
    font-weight: 300;
    align-self: start;
    color: var(--text-color);
}

.serieScreen_attachment {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    align-self: flex-end;
    color: #ffffffb4;
    background: rgba(86, 90, 129, 0.25);
    text-align: right;
    padding: 3px;
    cursor: pointer;
    margin-bottom: 20px;
    border-radius: 5px;
}

.serieScreen_attachment__info {
    display: flex;
    flex-direction: column;
    padding: 3px;
}

.serieScreen_attachment__info > h5 {
    color: #ffffffb4;
    text-decoration: none;
    font-family: 'InterTight', sans-serif;
}

.serieScreen_attachment__info > p {
    font-size: 0.5rem;
    color: #ffffffb4;
    text-decoration: none;
    font-family: 'InterTight', sans-serif;
}

.serieScreen__description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--text-color);
    background: rgba(86, 90, 129, 0.25);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 710px;
    margin-bottom: 10px;
    font-family: 'InterTight', sans-serif;
    border-radius: 5px;
}

.serieScreen__description > h4 {
    font-weight: 300;
    font-size: 1rem;
    margin: 20px;
    line-height: 120%;
    text-align: justify;
}

.serieScreen__description > button {
    margin-bottom: 10px;
    border: none;
    background-color: rgba(0, 0, 0, 0.0);
    color: var(--text-color);
    cursor: pointer;
    align-self: center;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;   
}

.serieScreen__description > button:hover {
    color: var(--text-color);
    text-decoration: underline;
}

.serieScreen__back {
    display: flex;
    justify-content: center;
    align-items: center;
}

.serieScreen__playlist {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 400px;
    width: 350px;
    background: rgba(86, 90, 129, 0.25);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    color: var(--text-color);
    margin-top: 145px;
    border-radius: 5px;
}

.serieScreen__playlist__content {
    background: rgba(99, 104, 149, 0.25);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.serieScreen__playlist > h4 {
    font-size: 1.2rem;
    font-weight: 300;
    margin: 10px;
}

.serieScreen__playlist__info {
    display: flex;
    flex-direction: row;
    padding: 10px;
    cursor: pointer;
    max-height: 60px;
    width: 90%;
    border-radius: 4px;
}

.serieScreen__playlist__info__thumbnail {
    padding-left: 15px;
    aspect-ratio: 16/9;
    max-width: 100px;
    border-radius: 4px;
}

.serieScreen__playlist__info > h5 {
    position: relative;
    font-size: 0.9rem;
    margin-left: 23px;
    margin-top: 5px;
    max-height: 50px;
    font-family: 'InterTight', sans-serif;
    font-weight: 300;
}

.serieScreen__playlist__info:hover {
    background: rgba(86, 90, 129, 0.5);
}

.serieScreen__playlist__info__active {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 10px;
    cursor: pointer;
    max-height: 60px;
    width: 90%;
    background: rgba(86, 90, 129, 0.3);
    border-radius: 4px;
}

.serieScreen__playlist__info__thumbnail__active {
    position: relative;
    left: -10px;
    aspect-ratio: 16/9;
    max-width: 100px;
    border-radius: 4px;
}

.serieScreen__playlist__info__active > h5 {
    position: relative;
    font-size: 0.9rem;
    margin-top: 5px;
    max-height: 50px;
    font-weight: 500;
    font-family: 'InterTight', sans-serif;
}