.planScreen {
    padding: 20px;
    max-width: 500px;
    background: rgba(45, 45, 35, 0.95);
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
}

.planScreen > h1{
    font-size: 33px;
    margin-bottom: 20px;
}

.planScreen > h2 {
    text-align: left;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 20px;
    font-family: 'InterTight', sans-serif;
}

.planScreen > button {
    padding: 10px 10px;
    font-size: 20px;
    color: var(--text-color);
    background-color: var(--primary-button-color);
    font-weight: 600;
    border: none;
    cursor: pointer;
    width: 100%;
    margin-top: 10px;
    font-family: 'InterTight', sans-serif;
    border-radius: 4px;
}

.planScreen__plan {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  border: 1px solid var(--text-color);
  height: 120px;
  margin-bottom: 15px;
  justify-content: space-between;
  padding: 20px;
  opacity: 0.8;
  border-radius: 6px;
}

.planScreen__plan__selected {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  border: 3px solid var(--text-color);
  color: var(--text-color);
  height: 120px;
  margin-bottom: 15px;
  font-weight: 600;
  justify-content: space-between;
  padding: 20px;
  border-radius: 6px;
}

.planScreen__plan__body {
  flex-direction: column;
  text-align: left;
  padding-right: 30px;
}

.planScreen__plan__price {
  display: flex;
  font-size: 25px;
  font-weight: 600;
  flex-direction: column;
  justify-content: center;
}

.planScreen__plan__body > p {
  font-size: 15px;
  margin-top: 10px;
  font-family: 'InterTight', sans-serif;
  text-align: justify;
}

.planScreen__plan__price > p {
    font-size: 20px;
}

@media (max-width: 768px) {
  .planScreen__plan {
    flex-direction: column;
    height: 90px;
  }
  .planScreen__plan__selected {
    flex-direction: column;
    height: 90px;
  }
  .planScreen__plan__body {
    padding: 0px;
    text-align: center;
  }
  .planScreen__plan__price {
    flex-direction: row;
    text-align: center;
  }
  .planScreen__plan__body > h2 {
    font-size: 30px;
  }
}
