.banner {
    position: relative;
    height: 448px;
    color: var(--text-color);
    object-fit: contain;
}

.banner__contents {
    margin-left: 30px;
    padding-top: 140px;
    height: 190px;
}

.banner__title {
    font-size: 3rem;
    font-weight: 800;
    padding-bottom: 0.3rem;
}

.banner__description {
    width: 45rem;
    line-height: 1.3;
    padding-top: 1rem;
    font-size: 0.8rem;
    max-width: 360px;
    height: 80px;
    font-family: 'InterTight', sans-serif;
}

.banner--fadeBottom {
    height: 7.4rem;
    background-image: linear-gradient(180deg, transparent, rgba(45, 45, 35, 0.61), var(--background-color))
}

.banner__button {
    cursor: pointer;
    color: var(--text-color);
    outline: none;
    border: none;
    font-weight: 700;
    border-radius: 0.2vw;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-right: 1rem;
    padding-top: 0.5rem;
    background-color: rgba(51, 51, 51, 0.5);
    padding-bottom: 0.5rem;
}

.banner__button:hover {
    color: var(--background-color);
    background-color: var(--text-color);
    transition: all 0.2s;
}