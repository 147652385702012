.unpaidScreen {
    max-width: 300px;
    padding: 70px;
    margin-left: auto;
    margin-right: auto;
    background: rgba(45, 45, 35, 0.95);
    border-radius: 10px;
}

.unpaidScreen > p {
    font-size: 1rem;
    font-family: 'InterTight', sans-serif;
    text-align: justify;
    margin-bottom: 30px;
}

.unpaidScreen > h1{
    font-size: 1.8rem;
    margin-bottom: 30px;
}

.unpaidScreen > button {
    padding: 10px 10px;
    font-size: 1.3rem;
    color: var(--text-color);
    background-color: var(--primary-button-color);
    font-weight: 600;
    border: none;
    cursor: pointer;
    width: 100%;
    margin-top: 10px;
    font-family: 'InterTight', sans-serif;
    border-radius: 4px;
}