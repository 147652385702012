.episodeScreen {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
}

.episodeScreen__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    padding-left: 10px;
    padding-right: 10px;
}

.episodeScreen__body > div > video {
    border-radius: 10px;
}

.episodeScreen__body > button {
    margin-bottom: 20px;
    align-self: start;
    border: none;
    background-color: rgba(0, 0, 0, 0.0);
    color: #ffffffb4;
    cursor: pointer;
    font-size: 1rem;
}
.episodeScreen__info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    border-radius: 10px;
}

.episodeScreen__info h1 {
    font-size: 1.8rem;
    align-self: start;
    color: var(--text-color);
    
}

.episodeScreen_attachment {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    align-self: flex-end;
    color: #ffffffb4;
    background: rgba(86, 90, 129, 0.25);
    text-align: right;
    padding: 3px;
    cursor: pointer;
    margin-bottom: 20px;
    border-radius: 5px;
}

.episodeScreen_attachment__info {
    display: flex;
    flex-direction: column;
    padding: 3px;
}

.episodeScreen_attachment__info > h5 {
    color: #ffffffb4;
    text-decoration: none;
    font-family: 'InterTight', sans-serif;
}

.episodeScreen_attachment__info > p {
    font-size: 0.5rem;
    color: #ffffffb4;
    text-decoration: none;
    font-family: 'InterTight', sans-serif;
}

.episodeScreen__description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--text-color);
    background: rgba(86, 90, 129, 0.25);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 90vh;
    margin-bottom: 10px;
    font-family: 'InterTight', sans-serif;
    border-radius: 5px;
}

.episodeScreen__description > h4 {
    font-weight: 300;
    font-size: 1rem;
    margin: 20px;
    line-height: 120%;
    text-align: justify;
}

.episodeScreen__description > button {
    margin-bottom: 10px;
    border: none;
    background-color: rgba(0, 0, 0, 0.0);
    color: var(--text-color);
    cursor: pointer;
    align-self: center;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;   
}

.episodeScreen__description > button:hover {
    color: var(--text-color);
    text-decoration: underline;
}

.episodeScreen__back {
    display: flex;
    justify-content: center;
    align-items: center;
}
