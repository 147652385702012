.row {
    color: var(--text-color);
    margin-left: 20px;
}

.row > h2 {
    font-size: 23px;
    font-weight: 300;
}

.row__left,
.row__right {
    margin-top: 175px;
    position: absolute;
    width: 0px;
    height: 0px;
    background-color: rgba(45, 45, 35, 0.6);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    cursor: pointer;
    opacity: 0;
    transition: width 0.5s;
}

@media only screen and (min-width: 768px) {
    .row:hover .row__left,
    .row:hover .row__right {
        width: 60px;
        height: 60px;
        opacity: 1;
        border-radius: 20px;
    }
}

.row__left {
    left: 0;
}

.row__right {
    right: 0;
}

.row__posters {
    display: flex;
    overflow-y: hidden;
    overflow-x: scroll;
    padding: 20px;
    transition: all ease 0.5s;
}

.row__posters::-webkit-scrollbar {
    display: none;
}

.row__posterContainer {
    position: relative;
    max-width: 250px;
    margin-right: 20px;
    object-fit: contain;
    transition: transform 450ms;
    cursor: pointer;
}

.row__posterContainer:hover {
    transform: scale(1.08);
    opacity: 1;
}

.row__progress {
    position: absolute;
    bottom: 4px;
    left: 0;
    height: 6px;
    opacity: 100%;
    background-color: var(--alert-color);
    border-radius: 0px 0px 0px 40px;
}