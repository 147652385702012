.sumary {
    width: 250px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: left;
    background-color: var(--background-color);
    height: 100%;
}

.sumary__logo {
    padding: 10px;
    width: 100px;
    cursor: pointer;
    border: none;
    align-self: center;
    max-height: 20%;
}

.sumary__body {
    overflow-y: auto;
    position: fixed;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    top: 70px;
    width: 250px;
    max-height: 50%;
}

.sumary__body > ul {
    list-style: none;
    padding: 15px;
}

.sumary__body > ul > li {
    padding-top: 20px;
}

.sumary__footer {
    max-height: 30%;
    position: fixed;
    bottom: 0;
    border: none;
    width: 250px;
}

.sumary__footer > ul {
    list-style: none;
    padding: 15px;
}   

.sumary__footer > ul > li {
    padding-top: 20px;
}

.sumary__item > button {
    display: flex;
    align-items: center;
    font-size: 1rem;
    color: var(--text-color);
    background-color: rgba(0, 0, 0, 0);
    border: none;
    cursor: pointer;
}

.sumary__item > button > svg {
    margin-right: 15px;
}

.sumary__footer > ul > li > button{
    padding: 10px 20px;
    font-size: 1rem;
    background-color: var(--alert-color);
    color: var(--text-color);
    font-weight: bold;
    border: none;
    cursor: pointer;
    width: 220px;
    border-radius: 4px;
}