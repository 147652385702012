input:invalid[focused="true"]{
    margin-bottom: 30px;
    border: 1px solid var(--alert-color);
}

input:invalid[focused="true"] ~ span{
    display: block;
    font-family: 'InterTight', sans-serif;
}

.input-container {
    position: relative;
    display: flex;
    align-items: center;
    
}

.input-container > input {
    padding: 10px;
    outline-width: 0;
    border: none;
    width: 100%;
    background-color: var(--text-color);
    margin-bottom: 15px;
    padding-right: 30px;
    font-size: 1rem;
    font-family: 'InterTight', sans-serif;
    border-radius: 4px;
    padding-left: 40px;
}

.input-container > span {
    position: absolute;
    top: 45px;
    font-size: 12px;
    color: var(--alert-color);
    display: none;
    margin-bottom: 10px;
}

.password__icon {
    position: absolute;
    top : 12px;
    right: 10px;
    color: gray;
    cursor: pointer;
}
