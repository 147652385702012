:root {
  --background-color: #2D2D23;
  --text-color: #F2E1D1;
  --primary-color: #565A81;
  --secondary-color: #BAB5C8;
  --primary-button-color: #44795A;
  --secondary-button-color: #999862;
  --alert-color: #ff4d4d;
}

body {
  max-width: 100%;
  overflow-x: hidden;
}

html {
  background-color: var(--background-color);
}

.app {
  background-color: var(--background-color);
}

/* Scrollbar border radius */
::-webkit-scrollbar {
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #303028;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #4d4d3b;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #27271e;
}

/* Webfont: JeremyMoore Regular */
@font-face {
  font-family: 'JeremyMoore';
  src: url('fonts/JeremyMoore.woff') format('woff'), /* Modern Browsers */
       url('fonts/JeremyMoore.woff2') format('woff2'), /* Modern Browsers */
       url('fonts/JeremyMoore.otf') format('opentype'); /* Safari, Android, iOS */
           font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: InterTight */
@font-face {
  font-family: 'InterTight';
  src: url('fonts/InterTight.ttf') format('truetype');
  font-style: normal;
  text-rendering: optimizeLegibility;
}